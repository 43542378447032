export const environment = {
  production: true,
  url: 'https://devapi.superlosmontes.com/api',
  // url: 'http://localhost:3000/api',
  urlPayment: 'https://secure.paguelofacil.com/LinkDeamon.cfm?',
  tokenPayment: '393D5BED999B51294677033ED48CFB04520875A75CFB3276BB1FC73DDD6AC7F059232E511802E09AF5B73B36E53FA9CD1841B7E4D97AD8268730F2D420D8394E',
  // urlPayment: 'https://sandbox.paguelofacil.com/LinkDeamon.cfm?',
  // tokenPayment: '8AACA8A03FCD5A7204FE09338375D894770714280B5445E49109FF9B04496B108366DF35A8787E3EC189437FF4BF07E0A086A5939E1BAFA1E7830495968CC1EE', // Testing
  versionApp: '1.0.1',
  // urlReturnPagueloFacilWeb: 'http://localhost:8100/response-payment',
  urlReturnPagueloFacilWeb: 'http://webwallet.supergarzon.com/response-payment',
  urlReturnPagueloFacilAPP: 'https://supergarzon.com/response.php',
  urlStore: 'https://www.superlosmontes.com/',
};
