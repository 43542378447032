import { Injectable } from '@angular/core';
import {LoadingController, Platform} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import {InAppBrowser} from "@ionic-native/in-app-browser/ngx";
import {SessionService} from "../session/session.service";

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    public sessionService: SessionService
  ) { }

  async presentLoading(text = 'Espere por favor!') {
    return  await this.loadingController.create({
      message: text,
      cssClass: 'my-custom-class',
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async actionConfirm(title, message, success, titleSuccess) {
    const confirm = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: titleSuccess,
          handler: success
        }
      ],
      backdropDismiss: false
    });

    await confirm.present();
  }

  async actionConfirmTwo(title, message, success, titleSuccess, cancel) {
    const confirm = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: titleSuccess,
          handler: success
        },
        {
          text: 'NO',
          handler: cancel
        }
      ],
      backdropDismiss: false
    });

    await confirm.present();
  }

  /**
 * formatDisplay(value, n, x, s, c)
 *
 * @param integer value: number
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 * output: (12345678.9, 2, 3, '.', ',');  --> "$ 12.345.678,90"
 * output (123456.789, 4, 4, ' ', ':');   --> "$ 12 3456:7890"
 * output (12345678.9, 3, '-');           --> "$ 12-345-679"
 */
  formatDisplayValue(value, n, x, s, c) {
    value = typeof(value) == 'number' ? value : Number(value);
    if (Number.isNaN(value)) {
      value = 0;
    }
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = value.toFixed(Math.max(0, ~~n));

    return ` ${(c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))}`;
  }

  async showAlert(title, content) {
    const alert = await this.alertController.create({
      header: title,
      message: content,
      buttons: ['OK']
    });

    await alert.present();
  }


  async verifyPassword(fun) {
    const alert = await this.alertController.create({
      header: 'Verificar',
      cssClass: 'modal-custom-css',
      inputs: [
        {
          name: 'password',
          placeholder: 'Contraseña',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {}
        },
        {
          text: 'Comprobar',
          handler: data => {
            fun(data);
          }
        }
      ]
    });

    await alert.present();
  }

  public objectHeader(client, wallet, type, typeView, ref = null) {
    return {
      "encabezados": {
        "id_number": `${client.id_number}` ,
        "name": `${client.firstname}`,
        "email": `${client.email}`,
        "mobile": `${client.mobile}`,
        "user": client['user'] ? client.user : null
      },
      "detalles": [
        {
          "id":null,
          "wallet_id":null,
          "moneda": wallet.typeMoney.moneda,
          "cliente_id":null,
          "factor":0,
          "monto": wallet.total,
          "localidad": typeView == 3 ? 998 : 999,
          "nro_fact":"0",
          "caja": "0",
          "tipotrans": type,
          "descriptrans": type == 0 ? typeView == 3 ? 'RECARGA' : "INGRESO TRANSFERENCIA" : "TRANSFERENCIA A TERCERO",
          "token": "",
          "trans": true,
          "codigo_ref": ref
        }
      ]
    };
  }

  public createObjectTransfer(origin: any, destination: any, amount: number) {
    let array = [];
    array.push({
      "encabezados": {
        "id_number": `${this.sessionService.user.id_number}` ,
        "name": `${this.sessionService.user.name}`,
        "email": `${this.sessionService.user.email}`,
        "mobile": `${this.sessionService.user.mobile}`
      },
      "detalles": {
        "id":null,
        "wallet_id":null,
        "moneda": origin.moneda,
        "cliente_id":null,
        "factor":0,
        "monto": amount,
        "localidad": 999,
        "nro_fact":"0",
        "caja": "0",
        "tipotrans": origin.tipotrans,
        "descriptrans": origin.descriptrans,
        "token": "",
        "trans": true,
        "codigo_ref": null
        }
    });

    array.push({
      "encabezados": {
        "id_number": `${this.sessionService.user.id_number}` ,
        "name": `${this.sessionService.user.name}`,
        "email": `${this.sessionService.user.email}`,
        "mobile": `${this.sessionService.user.mobile}`
      },
      "detalles": {
        "id":null,
        "wallet_id":null,
        "moneda": destination.moneda,
        "cliente_id":null,
        "factor":0,
        "monto": amount,
        "localidad": 999,
        "nro_fact":"0",
        "caja": "0",
        "tipotrans": destination.tipotrans,
        "descriptrans": destination.descriptrans,
        "token": "",
        "trans": true,
        "codigo_ref": null
      }
    })

    return array;
  }

  public msgResponse(value) {
    let msg;
    switch (value) {
      case 200:
        msg = 'Proceso Exitoso';
        break;
      case 204:
        msg = 'Formato incorrecto';
        break;
      case 205:
        msg = 'Error decodificando la cadena';
        break;
      case 1000:
        msg = 'Error al buscar el cliente';
        break;
      case 1001:
        msg = 'Error al crear el cliente';
        break;
      case 2000:
        msg = 'No se envio el arreglo de detalles';
        break;
      case 2001:
        msg = 'Error al crear monedero';
        break;
      case 2002:
        msg = 'Error al crear detalle de monedero';
        break;
      case 2003:
        msg = 'Error al actualizar monedero';
        break;
      case 3000:
        msg = 'Token caducó';
        break;
      case 3001:
        msg = 'Token no coincide';
        break;
      case 3002:
        msg = 'Fondos insuficiente';
        break;
      case 4000:
        msg = 'Localidad no existe';
        break;
      case 5000:
        msg = 'Moneda no existe';
        break;
      default:
        msg = 'Error inesperado';
    }
    return msg;
  }
}
